@import "../../constants";

.modal-content {
    font-family: $font;
    color: $greyDark;
}

.form-label {
    font-size: 16px;
    font-weight: bold;
}

.btn.btn-primary {
    border: none;
    background-color: $green;
    opacity: 1;
}
.btn.btn-primary:hover {
    background-color: $green;
    opacity: 0.7;
}
.btn.btn-primary:disabled {
    background-color: $greyLighter;
    color: $greyLight;
    opacity: 0.5;
}

.btn.btn-secondary {
    border: none;
    background-color: $greyLight;
}
.btn.btn-secondary:hover {
    background-color: $greyDark;
}
