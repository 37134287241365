@import "../../constants";

.kumul-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    font-family: $font;
    color: $greyDark;
}

.table-container {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    padding: 0 5px;
    position: relative;
    max-width: 1140px;
    width: 100%;
}

.table {
    display: flex;
    flex-flow: column;
    font-size: 18px;
}

.table-row {
    display: flex;
    border-bottom: 2px solid $greyLight;
}

.table-row.header {
    font-weight: bold;
}

.table-column {
    margin: 15px;
    overflow: hidden;
    white-space: nowrap;
}

.table-column.name {
    width: 150px;
    min-width: 150px;
}

.table-column.remaining {
    width: 100%;
}

.table-column.state {
    width: 80px;
    min-width: 80px;
    text-align: center;
}

.state-toggle.react-toggle .react-toggle-track {
    background-color: $greyDark;
}

.state-toggle.react-toggle .react-toggle-track:hover {
    opacity: 0.5;
}

.state-toggle.react-toggle--checked .react-toggle-track {
    background-color: $green;
}

.state-toggle.react-toggle--checked .react-toggle-track:hover {
    background-color: $green;
    opacity: 0.5;
}

.state-toggle.react-toggle .react-toggle-track {
    width: 46px;
    height: 20px;
}

.state-toggle.react-toggle .react-toggle-thumb {
    width: 18px;
    height: 18px;
    border: none;
}

// .state-toggle.react-toggle--checked .react-toggle-thumb:hover {
//     opacity: 0.5;
// }

.state-toggle.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
}

.state-toggle.react-toggle .react-toggle-track .react-toggle-track-x {
    width: 8px;
}

.table-column.edit {
    width: 100px;
    min-width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.icon {
    margin: 0 5px;
}

.icon:hover {
    opacity: 0.5;
    cursor: pointer;
}

.icon.new {
    color: white;
    background-color: $green;
    border-radius: 4px;
    width: 28px;
}

.icon.delete {
    color: $red;
}
