$green: #b3dc1f;
$greyLighter: #d8d8d8;
$greyLight: #9b9b9b;
$greyDark: #4a4a4a;
$red: #dc3545;

$font: Open Sans, sans-serif;
// -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif
