.webchat-container {
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
}

.transcript-container {
    clear: both;
    margin: 0 auto;
    position: relative;
    max-width: 980px;
    // width: 980px;
    flex-grow: 2;
}

.aside-container {
    width: 200px;
    max-width: 200px;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

@media (max-width: 980px) { 
    .transcript-container {
        width: auto;
    }

    .aside-container {
        display: none;
    }
}
