@import "../../../constants";

$fontSize: 18px;
$iconWidth: 34px;
$iconHeight: 30px;

.help-container {
    margin-left: 10px;
}

.help-icon {
    width: $iconWidth;
    height: $iconHeight;
}

.help-icon button {
    background: none;
    border-style: none;
    margin-left: 0;
    padding-left: 0;
    cursor: pointer;
}

.help-icon img {
    background-color: $green;
    border-radius: 200px;
    width: $iconWidth;
    height: $iconHeight;
}
  
.help-header {
    font-family: $font;
    font-size: $fontSize;
    color: $green;
    font-weight: bold;
    margin-top: 10px;
}

.help-text {
    font-family: $font;
    font-size: $fontSize;
    color: $greyDark;
    margin-top: 10px;
    overflow-y: hidden;
    text-align: left;
    overflow-wrap: break-word;
    line-height: 1.5;
}
