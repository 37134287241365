@import "../../constants";

.footer-container {
    pointer-events: none;
    margin: 0 auto;
    display: block;
    max-width: 1140px;
}

$menuBorder: $green 5px solid;

.progress-bar-container {
    padding: 15px;
    border-top: $menuBorder;
}
.progress-bar-container .progress {
    font-size: 14px;
    font-family: $font;
    font-weight: bold;
    background-color: $greyLighter;
}
.progress-bar-container .progress-bar {
    background-color: $green;
}

.menu {
    min-height: 25px;
    padding: 15px;
    border-bottom: $menuBorder;
    border-top: $menuBorder;
    background: white;
    pointer-events: all;
}

.menu-content {
    display: flex;
    text-align: center !important;
}

.list-inline-item {
    width: 33%;
    display: inline-block;
    font-size: 16px;
    font-family: $font;
    color: $greyDark;
}

.menu p {
    display: block;
    margin: 0;
}

.list-inline-item.disabled {
    color: lightgrey;
    pointer-events: none;
}

.list-inline-item.invisible {
    display: none;
}

.icon {
    padding: 5px 0;
    font-size: 16px;
}

.list-inline-item {
    cursor: pointer;
}

.list-inline-item:hover:not(.disabled) {
    background-color: #F1F3F3;
}

@keyframes highlight-pulse {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    66% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
    }
}

button.highlight {
    border-radius: 50%;
    position: relative;
    overflow: hidden;

    &::after {
        opacity: 0.3;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        background: $green;
        position: absolute;
        animation: highlight-pulse 2.2s ease infinite;
        will-change: opacity, transform;
    }
}

// @media (max-width: 980px) {
//     .menu p {
//         font-size: 14px;
//     }
// }

// @media (max-width: 840px) {
//     .menu p {
//         font-size: 12px;
//     }
// }

@media (max-width: 750px) {
    .menu p {
        font-size: 12px;
    }
}

@media (max-width: 640px) {
    .icon {
        padding: 0;
        font-size: 24px;
    }

    .menu p {
        display: none;
    }
}

@media (max-height: 600px) {
    .progress-bar-container {
        display: none;
    }
}

@media (max-height: 250px) {
    .footer-container {
        display: none;
    }
}
