@import "../../constants";

$logoBoxShadow: 0 0 5px $greyDark;

.logo-container {
    background: white;
    -moz-box-shadow: $logoBoxShadow;
    -webkit-box-shadow: $logoBoxShadow;
    box-shadow: $logoBoxShadow;
    border-bottom: $greyDark 1px solid;
}

.inner-container {
    position: relative;
    max-width: 968px;
    margin: 0 auto;
}

.logo {
    padding: 20px 0;
}

// #socials {
//     position: absolute;
//     right: 0;
//     top: 35px;
// }

// #socials a {
//     color: #657383;
//     display: inline-block;
//     margin-left: 2px;
// }

@media (max-width: 980px) {
    .logo {
        padding: 20px;
    }

    // #socials {
    //     right: 20px !important;
    // }
}

@media (max-width: 750px) {
    .logo {
        text-align: center;
        width: 100%;
        padding: 3px !important;
    }
}

@media (max-height: 500px) {
    .header-container {
        display: none;
    }
}

