@import "constants";

html {
  scroll-behavior: smooth;
}

// disable horizontal scrolling
html, body {
  max-width: 100%;
  min-width: 360px;
  overflow: hidden;
}

// fit full page
html, body, #root {
  width: 100%;
  height: 100%;
}
body, #root {
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
